<template>
 <div>
  <apexchart
   type="line"
   :options="chartOptions"
   :series="series"
   height="350" />
 </div>
</template>

<script>
export default {
 props: ["dataSeries", "labels"],
 components: {},
 data() {
  return {
   series: this.dataSeries,
   chartOptions: {
    chart: {
     type: "line", // This will be a mixed type chart
     height: 350,
     toolbar: {
      show: true,
     },
    },
    colors: ["#1E7889", "#E34B5F", "#1E7889"],
    stroke: {
     width: [0, 4, 4], // Width for column (0) and line (4)
    },
    xaxis: {
     type: "category",
     categories: this.labels,
     labels: {
      show: true,
      rotate: -75,
      style: {
       colors: [],
       fontSize: "12px",
       fontFamily: "Helvetica, Arial, sans-serif",
       fontWeight: 500,
       cssClass: "apexcharts-xaxis-label",
      },
      formatter: function (value, timestamp, opts) {
       let finalValue = "";
       if (value?.length > 0 && value?.length > 6) {
        let newDate = new Date(value);
        let year = newDate.getFullYear();
        let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
        let day = ("0" + newDate.getDate()).slice(-2);
        let formated = String(day) + "/" + String(month) + "/" + String(year);
        return formated;
       }
       if (value?.length == 6) {
        let formated = value?.slice(-2) + "/" + value?.slice(0, 4);
        return formated;
       }

       return value;
      },
     },
    },
    yaxis: [
     {
      title: {
       text: "Appels",
      },
     },
     {
      opposite: true,
      title: {
       text: "Taux de réponse",
      },
      labels: {
       show: true,
       formatter: function (value, timestamp, opts) {
        return value + "%";
       },
      },
     },
    ],
    tooltip: {
     shared: true,
     intersect: false,
    },
    plotOptions: {
     bar: {
      columnWidth: "50%",
     },
    },
   },
  };
 },
};
</script>

<style scoped>
/* You can add custom styles here */
</style>
