<template>
 <div class="ml-2 mr-10">
  <apexchart
   type="line"
   :options="chartOptions"
   :series="seriesData"
   height="350" />
 </div>
</template>

<script>
export default {
 props: ["dataSeries", "labels"],
 components: {},
 data() {
  return {
   // Data for the series
   seriesData: this.dataSeries,
   //   [
   //   {
   //    name: "Temps d'attente moyen (sec)",
   //    data: [5, 4.8, 5.2, 4.7, 5.1, 5.0, 4.9, 5.3, 5.2, 4.6, 4.9, 5.0],
   //   },
   //  ],
   // Chart options
   chartOptions: {
    chart: {
     id: "monthly-trends",
     toolbar: {
      show: false,
     },
    },
    xaxis: {
     type: "category",
     categories: this.labels,
     tickPlacement: "on",
     labels: {
      show: true,
      style: {
       colors: [],
       fontSize: "12px",
       fontFamily: "Helvetica, Arial, sans-serif",
       fontWeight: 500,
       cssClass: "apexcharts-xaxis-label",
      },
      formatter: function (value, timestamp, opts) {
       let finalValue = "";
       if (value?.length > 0 && value?.length > 6) {
        let newDate = new Date(value);
        let year = newDate.getFullYear();
        let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
        let day = ("0" + newDate.getDate()).slice(-2);
        let formated = String(day) + "/" + String(month) + "/" + String(year);
        return formated;
       }
       if (value?.length == 6) {
        let formated = value?.slice(-2) + "/" + value?.slice(0, 4);
        return formated;
       }

       return value;
      },
     },
    },
    yaxis: {
     title: {
      text: "Temps (sec)",
     },
     labels: {
      show: true,
      formatter: function (value, timestamp, opts) {
       if (value) {
        if (value > 86400) {
         let quotient = Math.floor(value / 86400);
         let remainder = value % 86400;
         value =
          quotient +
          "." +
          new Date(remainder * 1000).toISOString().substring(11, 19);
        } else {
         value = new Date(value * 1000).toISOString().substring(11, 19);
        }
        return value;
       }
      },
     },
    },
    stroke: {
     curve: "smooth",
    },
    title: {
     text: "Temps d'attente moyen",
     align: "center",
    },
    legend: {
     position: "top",
    },
    colors: ["#2563eb"],
   },
  };
 },
};
</script>

<style scoped>
/* Custom styles for the chart if needed */
</style>
